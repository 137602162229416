import "./wdyr";

import React from "react";
import { render } from "react-dom";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, Reducer } from "redux";
import thunkMiddleware from "redux-thunk";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
  isLoaded,
  FirebaseReducer,
} from "react-redux-firebase";
import { composeWithDevTools } from "redux-devtools-extension";

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/performance";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import {
  BUGSNAG_API_KEY,
  BUILD_BRANCH,
  BUILD_PULL_REQUESTS,
  BUILD_SHA1,
  BUILD_TAG,
} from "secrets";
import { FIREBASE_CONFIG } from "settings";

import { VenueTemplateReducers, MiscReducers } from "store/reducers";
// import { CacheActionTypes } from "store/actions/Cache";

import * as serviceWorker from "./serviceWorker";
import { activatePolyFills } from "./polyfills";

import { Firestore } from "types/Firestore";
import { User } from "types/User";

import { traceReactScheduler } from "utils/performance";
import { authSelector } from "utils/selectors";

import { CustomSoundsProvider } from "hooks/sounds";
import { useSelector } from "hooks/useSelector";

import { AppRouter } from "components/organisms/AppRouter";

import { LoadingPage } from "components/molecules/LoadingPage/LoadingPage";

import "scss/global.scss";
import { ThemeProvider } from "styled-components";
import { theme } from "theme/theme";

activatePolyFills();

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
firebaseApp.analytics();
firebaseApp.auth();
firebaseApp.firestore();
const firebaseFunctions = firebase.functions();
firebase.performance();

// Enable the functions emulator when running in development
if (process.env.NODE_ENV === "development") {
  firebaseFunctions.useFunctionsEmulator("http://localhost:5001");
}

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer as Reducer<FirebaseReducer.Reducer<User>>,
  firestore: firestoreReducer as Reducer<Firestore>,
  ...VenueTemplateReducers,
  ...MiscReducers,
});

export type RootState = ReturnType<typeof rootReducer>;

const initialState = {};
export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export type AppDispatch = typeof store.dispatch;

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

if (BUGSNAG_API_KEY) {
  const DEVELOPMENT = "development";
  const TEST = "test";
  const STAGING = "staging";
  const PRODUCTION = "production";
  const SPARKLE_ENVS = [
    "sparkleverse",
    "sparkle1",
    "sparkle2",
    "sparkle3",
    "sparkle4",
    "sparkle5",
    "sparkle6",
    "sparkle7",
    "sparkle8",
    "sparkle9",
    "sparkle10",
    "bigtop",
    "deloitte",
    "env/kotr",
    "env/memrise",
    "env/unesco",
    "env/ohbm",
    "env/pa",
    "env/demo",
    "env/unity",
    "env/clever",
    "env/burn",
    "env/burn-staging",
    "env/github",
    "env/summit-hack",
  ];

  // //load users every 60 seconda
  // setInterval(() => {
  //   store.dispatch({ type: CacheActionTypes.RELOAD_USER_CACHE });
  // }, 1000 * 60);
  // //initial loading of users
  // store.dispatch({ type: CacheActionTypes.RELOAD_USER_CACHE });
  // console.log("Dispatching", CacheActionTypes.RELOAD_USER_CACHE);

  const releaseStage = () => {
    if (
      window.location.host.includes("localhost") ||
      process.env.NODE_ENV === DEVELOPMENT
    ) {
      return DEVELOPMENT;
    }

    if (process.env.NODE_ENV === TEST) {
      return TEST;
    }

    if (
      window.location.host.includes(STAGING) ||
      BUILD_BRANCH?.includes(STAGING)
    ) {
      return STAGING;
    }

    if (BUILD_BRANCH?.includes("master")) {
      return PRODUCTION;
    }

    if (BUILD_BRANCH !== undefined && SPARKLE_ENVS.includes(BUILD_BRANCH)) {
      return BUILD_BRANCH;
    }

    return process.env.NODE_ENV;
  };

  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appType: "client",
    appVersion: BUILD_SHA1,
    enabledReleaseStages: [STAGING, PRODUCTION, ...SPARKLE_ENVS], // don't track errors in development/test
    releaseStage: releaseStage(),
    maxEvents: 25,
    metadata: {
      BUILD_SHA1,
      BUILD_TAG,
      BUILD_BRANCH,
      BUILD_PULL_REQUESTS,
    },
    onError: (event) => {
      const { currentUser } = firebase.auth();

      if (!currentUser) return;

      // Add user context to help locate related errors for support
      event.setUser(
        currentUser.uid,
        currentUser.email || undefined,
        currentUser.displayName || undefined
      );
    },
  });
}

// When BUGSNAG_API_KEY not set, stub out BugsnagErrorBoundary with a noop
const BugsnagErrorBoundary = BUGSNAG_API_KEY
  ? Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? React.Fragment
  : React.Fragment;

const AuthIsLoaded: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const auth = useSelector(authSelector);

  if (!isLoaded(auth)) return <LoadingPage />;

  return <>{children}</>;
};

traceReactScheduler("initial render", performance.now(), () => {
  render(
    <BugsnagErrorBoundary>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
              <AuthIsLoaded>
                <CustomSoundsProvider
                  loadingComponent={<LoadingPage />}
                  waitTillConfigLoaded
                >
                  <AppRouter />
                </CustomSoundsProvider>
              </AuthIsLoaded>
            </ReactReduxFirebaseProvider>
          </Provider>
        </DndProvider>
      </ThemeProvider>
    </BugsnagErrorBoundary>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
